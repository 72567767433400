@import '~bootstrap';
@import "toastr";
@import "font-awesome";

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Baloo+2:wght@400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/panton');
                


$primary: #00244b;
$secondary: #565656;
$third: #005ea3;

p{
    font-size: 23px;
    line-height: 1.2;
    font-family: 'Baloo 2', cursive;
}

img{
    max-width: 100%;
}

ul{
    font-weight: lighter;
    font-family: "Baloo 2", cursive;
    li{
        padding-bottom: 5px;
    }
}

.text-primary{
    color: $primary !important;
}
.text-secondary{
    color: $secondary !important;
}
.text-third{
    color: $third !important;
}

.f-baloo{
    font-family: 'Baloo 2', cursive;
}

.f-anton{
    font-family: 'Panton', sans-serif;
}

.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-18{
    font-size: 18px;
}
.fs-20{
    font-size: 20px;
}
.fs-22{
    font-size: 22px;
}
.fs-24{
    font-size: 24px;
}
.fs-26{
    font-size: 26px;
}
.fs-30{
    font-size: 30px;
}
.fs-40{
    font-size: 40px;
}
.fs-50{
    font-size: 50px;
}
.fs-58{
    font-size: 58px;
}
.fs-60{
    font-size: 60px;
}
.fs-62{
    font-size: 62px;
}
.fs-70{
    font-size: 70px;
}

body{
    font-family: 'Panton', sans-serif;
    color: $secondary;
    font-size: 18px;
    line-height: 1;
    text-align: justify;
    background-image: url(/assets/media/fundo.png);
    background-repeat: no-repeat;
    background-position: top center;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.btn-default{
    background-color: transparent;
    border-radius: 18px;
    text-align: center;
    border:2px solid $third;
    color: $secondary;
    padding: 10px 40px;
    display: inline-block;
    text-decoration: none;
    font-style: italic;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
}
.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}
.b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
}
.bi {
    vertical-align: -.125em;
    fill: currentColor;
}
.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
    .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }
}



.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
    color: $primary;
}

nav.navbar{
    padding: 40px 0px;
    .nav-link{
        text-transform: uppercase;
        font-size: 16px;
    }
}

.banner-main{    
    .container{
        padding-top: 160px;
        padding-bottom: 110px;
        background-image: url(/assets/media/pablo.png);
        background-repeat: no-repeat;
        background-position: 110% 0px;
    }
   
    h1{
        font-size: 55px;
        font-style: italic;
    }
}

.about{
    p{
        font-size: 18px;
    }
}

.call-to-action{
    padding-bottom: 180px;
    position: relative;
    color: #FFFFFF;
    &::before{
        content: '';
        display: block;
        position: absolute;
        width: 50%;
        height: 100%;
        background-image: url(/assets/media/mulher.png);
        background-repeat: no-repeat;
        background-position: 120px bottom;
    }
    .bg-call-to-action{
        background-color: $primary;
        border-radius: 35px;
        padding: 100px;
    }
    h2{
        color: $third;
        margin-bottom: 40px;
    }
    .btn-default{
        margin-top: 20px;
    }
}

.guarantee{
    padding: 30px 0px;
    .container{
        background-image: url(/assets/media/rapaz.png);
        background-repeat: no-repeat;
        background-position: top right;
        padding: 160px 0px 40px 0px;
    }    
}

.services{
    .service{
        background-color: $primary;
        border-radius: 35px;
        padding: 10px;
        margin-bottom: 15px;
        picture{
            width: 100%;
            display: inline-block;
            text-align: center;
            img{
                border-radius: 30px;
            }
        }        
        .title{
            text-align: center;
            color: $third;
            padding: 0px 10px 0px 10px;
            font-size: 24px;
            font-style: italic;
            min-height: 70px;
            margin-top: 40px;
            a{
                color: $third;
                text-decoration: none;
                &:hover{
                    color: #0d6efd;
                } 
            }
        }
    }
}

.newsletter{
    .box-newsletter{
        background-color: $primary;
        padding: 80px 40px;
        border-radius: 35px;
        .form-control{
            padding: 25px 15px;
            border-radius: 40px;
            margin-top: 30px;
        }
        .btn-primary{
            padding: 25px 15px;
            border-radius: 40px;
            margin-top: 30px;
            background-color: $third;
            border-color: $third;
            width: 100%;
        }
    }
}

.contact{
    .box-contact{
        background-color: $primary;
        padding: 40px;
        border-radius: 35px;
        .form-control{
            padding: 18px 15px;
            border-radius: 40px;
            margin-top: 20px;
        }
        textarea{
            padding: 18px 15px;
            border-radius: 40px;
            margin-top: 20px;
            width: 100%;
        }
        .btn-primary{
            padding: 25px 15px;
            border-radius: 40px;
            margin-top: 20px;
            background-color: $third;
            border-color: $third;
            width: 100%;
        }
        a{
            /* text-decoration: none; */
            color: #FFFFFF;
        }
    }
}

.blog{
    text-align: center;
    background-color: #f0f0f0;
    .blog-item{
        margin-bottom: 30px;
        .title{
            padding: 15px 0px;
            min-height: 80px;
            a{
                text-decoration: none;
                color: $primary;
                font-size: 25px;
            }
        }
        p{
            font-size: 18px;
        } 
        
    }
}


.blog-page{
    text-align: center;
    .blog-item{
        margin-bottom: 30px;
        .title{
            padding: 15px 0px;
            min-height: 80px;
            a{
                text-decoration: none;
                color: $primary;
                font-size: 25px;
            }
        }
        p{
            font-size: 18px;
        }
    }
    .blog-description{
        picture{
            float: left;
            margin-right: 15px;
            margin-bottom: 15px;
        }
        p{
            font-size: 18px;
        }
    }
}

footer{
    &.footer{
        color: #FFFFFF;
        background-color: #f0f0f0 !important;
        background: url(/assets/media/final.png) ;
        background-repeat: repeat-x;
        background-position: center -120px;
        padding-top: 9rem !important;
        .footer_bg{
            background-color: #00244b;
        }
        a{
            text-decoration: none;
        }
        .footer-signature{
            font-size: 16px;
            line-height: 1.2;
            font-family: 'Baloo 2', cursive;
            padding: 20px 0px 15px 0px;
            margin-top: 30px;
            border-top: 1px solid $third;
        }
    }
}




@media (max-width: 991px) {
    .banner-main{
        .container{
            background-image:none;
        }
    }
    .call-to-action{
        &::before{
            width: 50%;
            height: 50%;
            background-size: contain;
            bottom: 20px;
            right: 0px;
        }
        .bg-call-to-action{
            padding: 30px;
        }
    }
    .guarantee{
        .container{
            background-image:none;
        }
    }
    .box-newsletter{
        h2,p{
            text-align: center;
        }
    }
    .blog{
        margin-bottom: 0px !important;
    }
       
}

@media (max-width: 767px) {
    body{
        text-align: center;
    }
    .fs-62{
        font-size: 48px;
    }
    .fs-60{
        font-size: 45px;
    }
    .banner-main h1, .banner-main .h1{
        font-size: 28px;
    }
    .call-to-action{
        padding-bottom: 0px;
        &::before{
            content:none;
        }
    }
   
    footer.footer{
        padding-top: 0rem !important;
    }

    .guarantee{
        .container{
            padding: 40px 15px 40px 15px;
        }
        
    }
    .navbar-nav{
        background: #F1F1F1;
        border-radius: 10px;
    }
    
}